<template>
  <div>
    <div v-if="!hidden" class="popup-slider-wrapper">
      <div class="close-btn-container">
        <span @click="closePopup" class="close-btn"></span>
      </div>
      <div class="popup-slider-container">
        <swiper
            :slides-per-view="1"
            navigation
            @swiper="onSwiper"
            :initial-slide="initialPhoto - 1"
        >
          <swiper-slide
              :key="slide.id"
              v-for="slide in photos"
          >
            <img :src="slide.src" :alt="slide.alt">
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation]);

export default {
  name: 'ProjectPopupSlider',
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['photos', 'initialPhoto', 'hidden'],
  methods: {
    closePopup() {
      this.$emit('close-popup', true)
    },
    onSwiper(swiper) {
      console.log(swiper);
    },
  }
}
</script>

<style scoped>
@import "./../assets/css/project-popup-slider.css";
</style>