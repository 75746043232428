<template>
  <div class="page-container">
    <ProjectMainContainer :main-img="mainImg"/>

    <ProjectDescriptionContainer
        :project-title="projectTitle"
        :project-description="projectDescription"
    />

    <ProjectDetailedPhotos
        :project-photos="projectPhotos"
        @photo-clicked="photoClicked"
    />

    <ProjectPopupSlider
        :photos="projectPhotosPopup"
        :hidden="isPopupHidden"
        :initial-photo="initialPhoto"
        @close-popup="showPopup"
    />

    <ProjectsBottomMenu
        :objects-type="'architecture'"
    />
  </div>
</template>

<script>
import ProjectMainContainer from './../../components/ProjectMainContainer';
import ProjectDescriptionContainer from './../../components/ProjectDescriptionContainer';
import ProjectDetailedPhotos from './../../components/ProjectDetailedPhotos';
import ProjectPopupSlider from './../../components/ProjectPopupSlider';
import ProjectsBottomMenu from './../../components/ProjectsBottomMenu';

export default {
  name: 'DomUOzera',
  components: {
    ProjectMainContainer,
    ProjectDescriptionContainer,
    ProjectDetailedPhotos,
    ProjectPopupSlider,
    ProjectsBottomMenu,
  },
  data() {
    return {
      mainImg: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-main.jpg'),
      projectTitle: 'Дом у озера',
      projectDescription: [
        {
          id: 1,
          text: 'Команда: Максим Цыбин, Анисина Анастасия',
        },
        {
          id: 2,
          text: 'Назначение: Загородный дом у озера',
        },
        {
          id: 3,
          text: 'Площадь: 300 м²',
        },
        {
          id: 4,
          text: 'Адрес: Республика Карелия',
        },
        {
          id: 5,
          text: 'Год: 2013'
        }
      ],
      projectPhotos: [
        {
          id: 'photos-column-1',
          list: [
            {
              id: 'column-1-photo-1',
              index: 1,
              src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-1.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-2',
              index: 4,
              src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-4.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-3',
              index: 7,
              src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-7.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-4',
              index: 10,
              src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-10.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-5',
              index: 13,
              src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-13.jpg'),
              alt: ''
            },
          ]
        },
        {
          id: 'photos-column-2',
          list: [
            {
              id: 'column-2-photo-1',
              index: 2,
              src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-2.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-2',
              index: 5,
              src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-5.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-3',
              index: 8,
              src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-8.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-4',
              index: 11,
              src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-11.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-5',
              index: 14,
              src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-14.jpg'),
              alt: ''
            },
          ]
        },
        {
          id: 'photos-column-3',
          list: [
            {
              id: 'column-3-photo-1',
              index: 3,
              src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-3.jpg'),
              alt: ''
            },
            {
              id: 'column-3-photo-2',
              index: 6,
              src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-6.jpg'),
              alt: ''
            },
            {
              id: 'column-3-photo-2',
              index: 9,
              src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-9.jpg'),
              alt: ''
            },
            {
              id: 'column-3-photo-3',
              index: 12,
              src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-12.jpg'),
              alt: ''
            },
            {
              id: 'column-3-photo-4',
              index: 15,
              src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-15.jpg'),
              alt: ''
            },
          ]
        }
      ],
      projectPhotosPopup: [
        {
          id: 'popup-photo-1',
          src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-1-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-2',
          src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-2-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-3',
          src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-3-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-4',
          src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-4-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-5',
          src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-5-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-6',
          src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-6-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-7',
          src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-7-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-8',
          src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-8-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-9',
          src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-9-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-10',
          src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-10-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-11',
          src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-11-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-12',
          src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-12-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-13',
          src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-13-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-14',
          src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-14-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-15',
          src: require('./../../assets/img/projects/dom-u-ozera/dom-u-ozera-15-big.jpg'),
          alt: ''
        },
      ],
      isPopupHidden: true,
      initialPhoto: 1,
    }
  },
  methods: {
    photoClicked(index) {
      this.initialPhoto = index;
      this.isPopupHidden = false;
    },
    showPopup(flag) {
      this.isPopupHidden = flag;
    }
  }
}
</script>