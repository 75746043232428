<template>
  <div class="site-container">
    <header>
      <div class="header-content">
        <a href="/" class="site-logo"></a>

        <div
            @click="openHideMobileMenu"
            :class="['site-menu-container', menuOpened ? 'opened' : '']"
        >
          <span class="mobile-menu-button">
            <span class="mobile-menu-button-line"></span>
            <span class="mobile-menu-button-line"></span>
            <span class="mobile-menu-button-line"></span>
          </span>
          <ul class="site-menu">
            <li class="site-menu-item-container">
              <router-link to="/" class="site-menu-item" exact>
                Интерьер
              </router-link>
            </li>
            <li class="site-menu-item-container">
              <router-link to="/architecture" class="site-menu-item" exact>
                Архитектура
              </router-link>
            </li>
            <li class="site-menu-item-container">
              <router-link to="/publications" class="site-menu-item" exact>
                Публикации
              </router-link>
            </li>
            <li class="site-menu-item-container">
              <router-link to="/contacts" class="site-menu-item" exact>
                Контакты
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </header>
    <div class="site-content">
      <router-view></router-view>
    </div>
    <footer>
      <div class="footer-content">
        <div class="footer-contacts-container">
          <a href="mailto:ts@tsanarch.com" class="footer-link email-link">ts@tsanarch.com</a>
          <a href="tel:79111747232" class="footer-link phone-link">+7 911 174 72 32</a>
        </div>
        <div class="footer-social-container">
          <a href="https://instagram.com/tsan_architects" target="_blank" rel="nofollow" class="instagram-link"></a>
          <a href="https://www.behance.net/tscd57" target="_blank" rel="nofollow" class="be-link">interior</a>
          <a href="https://www.behance.net/tsanarchit760d" target="_blank" rel="nofollow" class="be-link">architecture</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      menuOpened: false,
    }
  },
  methods: {
    openHideMobileMenu() {
      this.menuOpened = !this.menuOpened;
    }
  }
}
</script>

<style>
@import "./assets/css/shared.css";
</style>
