<template>
  <div class="page-container">
    <ProjectMainContainer :main-img="mainImg"/>

    <ProjectDescriptionContainer
        :project-title="projectTitle"
        :project-description="projectDescription"
    />

    <ProjectDetailedPhotos
        :project-photos="projectPhotos"
        @photo-clicked="photoClicked"
    />

    <ProjectPopupSlider
        :photos="projectPhotosPopup"
        :hidden="isPopupHidden"
        :initial-photo="initialPhoto"
        @close-popup="showPopup"
    />

    <ProjectsBottomMenu/>
  </div>
</template>

<script>
import ProjectMainContainer from './../../components/ProjectMainContainer';
import ProjectDescriptionContainer from './../../components/ProjectDescriptionContainer';
import ProjectDetailedPhotos from './../../components/ProjectDetailedPhotos';
import ProjectPopupSlider from './../../components/ProjectPopupSlider';
import ProjectsBottomMenu from './../../components/ProjectsBottomMenu';

export default {
  name: 'Dnevnik',
  components: {
    ProjectMainContainer,
    ProjectDescriptionContainer,
    ProjectDetailedPhotos,
    ProjectPopupSlider,
    ProjectsBottomMenu,
  },
  data() {
    return {
      mainImg: require('./../../assets/img/projects/dnevnik/dnevnik-main-jpg.jpg'),
      projectTitle: 'Ресторан "DNEVNIK"',
      projectDescription: [
        {
          id: 1,
          text: 'Команда: дизайнер Дарьяна Мамотенко danyamay.ru ' +
              'при участии Артема Алексеева',
        },
        {
          id: 2,
          text: 'Назначение: Ресторан',
        },
        {
          id: 3,
          text: 'Площадь: 120 м²',
        },
        {
          id: 4,
          text: 'Адрес: г.Санкт-Петербург, ул. Восстания, 55',
        },
        {
          id: 5,
          text: 'Фотограф: Дмитрий Цыренщиков',
        },
        {
          id: 6,
          text: 'Год: 2017'
        }
      ],
      projectPhotos: [
        {
          id: 'photos-column-1',
          list: [
            {
              id: 'column-1-photo-1',
              index: 1,
              src: require('./../../assets/img/projects/dnevnik/dnevnik-1.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-2',
              index: 4,
              src: require('./../../assets/img/projects/dnevnik/dnevnik-4.jpg'),
              alt: ''
            },
          ]
        },
        {
          id: 'photos-column-2',
          list: [
            {
              id: 'column-2-photo-1',
              index: 2,
              src: require('./../../assets/img/projects/dnevnik/dnevnik-2.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-2',
              index: 5,
              src: require('./../../assets/img/projects/dnevnik/dnevnik-5.jpg'),
              alt: ''
            },
          ]
        },
        {
          id: 'photos-column-3',
          list: [
            {
              id: 'column-3-photo-1',
              index: 3,
              src: require('./../../assets/img/projects/dnevnik/dnevnik-3.jpg'),
              alt: ''
            },
            {
              id: 'column-3-photo-2',
              index: 6,
              src: require('./../../assets/img/projects/dnevnik/dnevnik-6.jpg'),
              alt: ''
            }
          ]
        }
      ],
      projectPhotosPopup: [
        {
          id: 'popup-photo-1',
          src: require('./../../assets/img/projects/dnevnik/dnevnik-1-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-2',
          src: require('./../../assets/img/projects/dnevnik/dnevnik-2-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-3',
          src: require('./../../assets/img/projects/dnevnik/dnevnik-3-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-4',
          src: require('./../../assets/img/projects/dnevnik/dnevnik-4-big.jpg'),
          alt: ''
        },{
          id: 'popup-photo-5',
          src: require('./../../assets/img/projects/dnevnik/dnevnik-5-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-6',
          src: require('./../../assets/img/projects/dnevnik/dnevnik-6-big.jpg'),
          alt: ''
        },
      ],
      isPopupHidden: true,
      initialPhoto: 1,
    }
  },
  methods: {
    photoClicked(index) {
      this.initialPhoto = index;
      this.isPopupHidden = false;
    },
    showPopup(flag) {
      this.isPopupHidden = flag;
    }
  }
}
</script>