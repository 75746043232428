<template>
  <div class="page-container">
    <ProjectMainContainer :main-img="mainImg"/>

    <ProjectDescriptionContainer
        :project-title="projectTitle"
        :project-description="projectDescription"
    />

    <div class="about-container">
      <div class="about-text-container">
        <p class="about-text">
          Основная идея-простота,переосмысление формы и назначения. Использование железобетонных колец позволяет сократить время строительства до минимума.Внетреннее пространство микродома  можно использовать под любую предложенную функцию: coworking,микро-кинотеатр,микро-магазин,микро-отель,микро-чайная. Благодаря цилиндрической форме и большому весу дома (около 4.5 т), возможность смещения или повреждения конструкции практически невозможна. Площадь дома зависит от количества бетонных колец и, следовательно, может меняться как в большую, так и в меньшую сторону. Лаконичный вид микродома отлично впишется в любую ландшафтную среду.
        </p>
        <p class="about-text">
          При строительстве микродома применияются следующие материалы:
          <br>1.Три бетонных кольца ( d вн.-2000мм,  d нар.-2200мм, h- 900мм, толщина стены – 100мм ).
          <br>2. Металлический каркас для скрепления колец между собой (стальная труба 60х30мм).
          <br>3. С одной стороны  трубы – окно (без импостов), с другой утепленная деревянная дверь.
          <br>4.  Внутренняя отделка  - OSB панели (6мм)  по утеплителю (50мм).
        </p>
      </div>
    </div>

    <ProjectDetailedPhotos
        :project-photos="projectPhotos"
        @photo-clicked="photoClicked"
    />

    <ProjectPopupSlider
        :photos="projectPhotosPopup"
        :hidden="isPopupHidden"
        :initial-photo="initialPhoto"
        @close-popup="showPopup"
    />

    <ProjectsBottomMenu
        :objects-type="'architecture'"
    />
  </div>
</template>

<script>
import ProjectMainContainer from './../../components/ProjectMainContainer';
import ProjectDescriptionContainer from './../../components/ProjectDescriptionContainer';
import ProjectDetailedPhotos from './../../components/ProjectDetailedPhotos';
import ProjectPopupSlider from './../../components/ProjectPopupSlider';
import ProjectsBottomMenu from './../../components/ProjectsBottomMenu';

export default {
  name: 'MicroDom',
  components: {
    ProjectMainContainer,
    ProjectDescriptionContainer,
    ProjectDetailedPhotos,
    ProjectPopupSlider,
    ProjectsBottomMenu,
  },
  data() {
    return {
      mainImg: require('./../../assets/img/projects/microdom/microdom-main.jpg'),
      projectTitle: 'Микродом',
      projectDescription: [
        {
          id: 1,
          text: 'Команда: Максим Цыбин, Анисина Анастасия',
        },
        {
          id: 2,
          text: 'Назначение: Микродом смешанной функции',
        },
        {
          id: 3,
          text: 'Площадь: 6 м²',
        },
        {
          id: 4,
          text: 'Адрес: г.Москва, ул. Крымский Вал, 2'
        },
        {
          id: 5,
          text: 'Год: 2014'
        }
      ],
      projectPhotos: [
        {
          id: 'photos-column-1',
          list: [
            {
              id: 'column-1-photo-1',
              index: 1,
              src: require('./../../assets/img/projects/microdom/microdom-1.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-2',
              index: 4,
              src: require('./../../assets/img/projects/microdom/microdom-4.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-3',
              index: 7,
              src: require('./../../assets/img/projects/microdom/microdom-7.jpg'),
              alt: ''
            },
          ]
        },
        {
          id: 'photos-column-2',
          list: [
            {
              id: 'column-2-photo-1',
              index: 2,
              src: require('./../../assets/img/projects/microdom/microdom-2.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-2',
              index: 5,
              src: require('./../../assets/img/projects/microdom/microdom-5.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-3',
              index: 8,
              src: require('./../../assets/img/projects/microdom/microdom-8.jpg'),
              alt: ''
            },
          ]
        },
        {
          id: 'photos-column-3',
          list: [
            {
              id: 'column-3-photo-1',
              index: 3,
              src: require('./../../assets/img/projects/microdom/microdom-3.jpg'),
              alt: ''
            },
            {
              id: 'column-3-photo-2',
              index: 6,
              src: require('./../../assets/img/projects/microdom/microdom-6.jpg'),
              alt: ''
            }
          ]
        }
      ],
      projectPhotosPopup: [
        {
          id: 'popup-photo-1',
          src: require('./../../assets/img/projects/microdom/microdom-1-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-2',
          src: require('./../../assets/img/projects/microdom/microdom-2-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-3',
          src: require('./../../assets/img/projects/microdom/microdom-3-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-4',
          src: require('./../../assets/img/projects/microdom/microdom-4-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-5',
          src: require('./../../assets/img/projects/microdom/microdom-5-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-6',
          src: require('./../../assets/img/projects/microdom/microdom-6-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-7',
          src: require('./../../assets/img/projects/microdom/microdom-7-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-8',
          src: require('./../../assets/img/projects/microdom/microdom-8-big.jpg'),
          alt: ''
        },
      ],
      isPopupHidden: true,
      initialPhoto: 1,
    }
  },
  methods: {
    photoClicked(index) {
      this.initialPhoto = index;
      this.isPopupHidden = false;
    },
    showPopup(flag) {
      this.isPopupHidden = flag;
    }
  }
}
</script>