<template>
  <div class="page-container">
    <ProjectMainContainer :main-img="mainImg"/>

    <ProjectDescriptionContainer
        :project-title="projectTitle"
        :project-description="projectDescription"
    />

    <ProjectDetailedPhotos
        :project-photos="projectPhotos"
        @photo-clicked="photoClicked"
    />

    <ProjectPopupSlider
        :photos="projectPhotosPopup"
        :hidden="isPopupHidden"
        :initial-photo="initialPhoto"
        @close-popup="showPopup"
    />

    <ProjectsBottomMenu/>
  </div>
</template>

<script>
import ProjectMainContainer from './../../components/ProjectMainContainer';
import ProjectDescriptionContainer from './../../components/ProjectDescriptionContainer';
import ProjectDetailedPhotos from './../../components/ProjectDetailedPhotos';
import ProjectPopupSlider from './../../components/ProjectPopupSlider';
import ProjectsBottomMenu from './../../components/ProjectsBottomMenu';

export default {
  name: 'SBBurgers2',
  components: {
    ProjectMainContainer,
    ProjectDescriptionContainer,
    ProjectDetailedPhotos,
    ProjectPopupSlider,
    ProjectsBottomMenu,
  },
  data() {
    return {
      mainImg: require('./../../assets/img/projects/sb-burgers-2/sb-burgers-main.jpg'),
      projectTitle: 'Ресторан-бар "SB&BURGERS"',
      projectDescription: [
        {
          id: 1,
          text: 'Команда: Максим Цыбин, Анисина Анастасия, при участии Артема Алексеева',
        },
        {
          id: 2,
          text: 'Назначение: Ресторан',
        },
        {
          id: 3,
          text: 'Площадь: 80 м²',
        },
        {
          id: 4,
          text: 'Адрес: г.Санкт-Петербург, Невский проспект, 29',
        },
        {
          id: 5,
          text: 'Фотограф: Дмитрий Цыренщиков',
        },
        {
          id: 6,
          text: 'Год: 2016'
        }
      ],
      projectPhotos: [
        {
          id: 'photos-column-1',
          list: [
            {
              id: 'column-1-photo-1',
              index: 1,
              src: require('./../../assets/img/projects/sb-burgers-2/sb-burgers-1.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-2',
              index: 4,
              src: require('./../../assets/img/projects/sb-burgers-2/sb-burgers-4.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-3',
              index: 6,
              src: require('./../../assets/img/projects/sb-burgers-2/sb-burgers-6.jpg'),
              alt: ''
            },
          ]
        },
        {
          id: 'photos-column-2',
          list: [
            {
              id: 'column-2-photo-1',
              index: 2,
              src: require('./../../assets/img/projects/sb-burgers-2/sb-burgers-2.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-2',
              index: 5,
              src: require('./../../assets/img/projects/sb-burgers-2/sb-burgers-5.jpg'),
              alt: ''
            },
          ]
        },
        {
          id: 'photos-column-3',
          list: [
            {
              id: 'column-3-photo-1',
              index: 3,
              src: require('./../../assets/img/projects/sb-burgers-2/sb-burgers-3.jpg'),
              alt: ''
            },
          ]
        }
      ],
      projectPhotosPopup: [
        {
          id: 'popup-photo-1',
          src: require('./../../assets/img/projects/sb-burgers-2/sb-burgers-1-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-2',
          src: require('./../../assets/img/projects/sb-burgers-2/sb-burgers-2-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-3',
          src: require('./../../assets/img/projects/sb-burgers-2/sb-burgers-3-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-4',
          src: require('./../../assets/img/projects/sb-burgers-2/sb-burgers-4-big.jpg'),
          alt: ''
        },{
          id: 'popup-photo-5',
          src: require('./../../assets/img/projects/sb-burgers-2/sb-burgers-5-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-6',
          src: require('./../../assets/img/projects/sb-burgers-2/sb-burgers-6-big.jpg'),
          alt: ''
        },
      ],
      isPopupHidden: true,
      initialPhoto: 1,
    }
  },
  methods: {
    photoClicked(index) {
      this.initialPhoto = index;
      this.isPopupHidden = false;
    },
    showPopup(flag) {
      this.isPopupHidden = flag;
    }
  }
}
</script>