<template>
  <div>
    <div class="project-main-container">
      <img class="project-main" :src="mainImg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectMainContainer',
  props: ['mainImg']
}
</script>

<style scoped>
@import "./../assets/css/project-main-container.css";
</style>