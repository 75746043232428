<template>
  <div>
    <div class="page-container">
      <div class="projects-grid">
        <router-link
            v-for="(item, index) in projects"
            :to="item.path"
            :key="'id-project-' + index"
            class="projects-grid-item"
        >
          <div class="project-cover-container">
            <img :src="item.cover" :alt="item.name">

            <div class="project-green-overlay">
              <p class="project-title">{{ item.name }}</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArchitecturePage',
  data() {
    return {
      projects: [
        {
          name: 'Жилой дом',
          cover: require('./../assets/img/projects/jiloy-dom/jiloy-dom-cover.jpg'),
          path: '/jiloy-dom',
        },
        {
          name: 'Дуплекс',
          cover: require('./../assets/img/projects/dupleks/dupleks-cover.jpg'),
          path: '/dupleks',
        },
        {
          name: 'Научный центр',
          cover: require('./../assets/img/projects/nauchny-tsentr/nauchny-tsentr-cover.jpg'),
          path: '/nauchny-tsentr',
        },
        {
          name: 'Дом у озера',
          cover: require('./../assets/img/projects/dom-u-ozera/dom-u-ozera-cover.jpg'),
          path: '/dom-u-ozera',
        },
        {
          name: 'Экодом',
          cover: require('./../assets/img/projects/ecodom/ecodom-cover.jpg'),
          path: '/ecodom',
        },
        {
          name: 'Микродом',
          cover: require('./../assets/img/projects/microdom/microdom-cover.jpg'),
          path: '/microdom',
        },
      ]
    }
  }
}
</script>

<style>
@import "./../assets/css/main-page.css";
</style>