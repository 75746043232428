<template>
  <div>
    <ul class="bottom-menu">
      <li
          :key="item.id"
          v-for="item in getLinks()"
          class="bottom-menu-item"
      >
        <router-link
            :to="item.to"
            class="site-menu-item"
        >
          {{ item.label }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ProjectsBottomMenu',
  props: {
    objectsType: {
      type: String,
      default: 'interior',
    }
  },
  data() {
    return {
      links: [
        {
          id: 'id-bottom-menu-1',
          label: 'Барионикс',
          to: '/barioniks'
        },
        {
          id: 'id-bottom-menu-2',
          label: 'SB&Burgers',
          to: '/sb-burgers'
        },
        {
          id: 'id-bottom-menu-3',
          label: 'Go gyros',
          to: '/go-gyros'
        },
        {
          id: 'id-bottom-menu-4',
          label: 'Bad clown burgers',
          to: '/bad-clown-burgers'
        },
        {
          id: 'id-bottom-menu-5',
          label: 'Тихий',
          to: '/tihii'
        },
        {
          id: 'id-bottom-menu-6',
          label: 'Dnevnik',
          to: '/dnevnik'
        },
        {
          id: 'id-bottom-menu-7',
          label: 'Батискаф',
          to: '/batiskaf'
        },
        {
          id: 'id-bottom-menu-8',
          label: 'SB&Burgers',
          to: '/sb-burgers-2'
        },
        {
          id: 'id-bottom-menu-9',
          label: 'Central park',
          to: '/central-park'
        },
        {
          id: 'id-bottom-menu-10',
          label: 'Hitch',
          to: '/hitch'
        },
        {
          id: 'id-bottom-menu-11',
          label: 'FC Zenit',
          to: '/zenit'
        },
        {
          id: 'id-bottom-menu-12',
          label: 'Apartment 74',
          to: '/apartment-74'
        },
        {
          id: 'id-bottom-menu-13',
          label: 'Sea Can',
          to: '/sea-can'
        },
      ],
      archLinks: [
        {
          id: 'id-bottom-menu-1',
          label: 'Жилой дом',
          to: '/jiloy-dom'
        },
        {
          id: 'id-bottom-menu-2',
          label: 'Дуплекс',
          to: '/dupleks'
        },
        {
          id: 'id-bottom-menu-3',
          label: 'Научный центр',
          to: '/nauchny-tsentr'
        },
        {
          id: 'id-bottom-menu-4',
          label: 'Дом у озера',
          to: '/dom-u-ozera'
        },
        {
          id: 'id-bottom-menu-5',
          label: 'Экодом',
          to: '/ecodom'
        },
        {
          id: 'id-bottom-menu-6',
          label: 'Микродом',
          to: '/microdom'
        },
      ]
    }
  },
  methods: {
    getLinks() {
      return this.objectsType === 'interior' ? this.links : this.archLinks;
    }
  }
}
</script>

<style scoped>
.bottom-menu {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  margin: 0;
  padding: 0 0 30px 0;
  box-sizing: border-box;
}
.bottom-menu-item {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
  margin: 30px 15px 0 0;
  padding: 0;
  vertical-align: top;
}

@media screen and (max-width: 992px) {
  .bottom-menu-item {
    display: block;
    margin: 15px 0 0 0;
  }
  .bottom-menu {
    width: 200px;
    margin: 0 auto;
  }
}
</style>