<template>
  <div>
    <div class="page-container">
      <div class="projects-grid">
        <router-link
          v-for="(item, index) in projects"
          :to="item.path"
          :key="'id-project-' + index"
          class="projects-grid-item"
        >
          <div class="project-cover-container">
            <img :src="item.cover" :alt="item.name">

            <div class="project-green-overlay">
              <p class="project-title">{{ item.name }}</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainPage',
  data() {
    return {
      projects: [
        {
          name: 'Sea Can',
          cover: require('./../assets/img/projects/sea-can/sea-can-cover.jpg'),
          path: '/sea-can',
        },
        {
          name: 'Apartment 74',
          cover: require('./../assets/img/projects/apartment-74/apartment-74-cover.jpg'),
          path: '/apartment-74',
        },
        {
          name: 'FC Zenit',
          cover: require('./../assets/img/projects/zenit/zenit-cover.jpg'),
          path: '/zenit',
        },
        {
          name: 'Hitch',
          cover: require('./../assets/img/projects/hitch/hitch-cover.jpg'),
          path: '/hitch',
        },
        {
          name: 'Central park',
          cover: require('./../assets/img/projects/central-park/central-park-cover.jpg'),
          path: '/central-park',
        },
        {
          name: 'SB&Burgers',
          cover: require('./../assets/img/projects/sb-burgers/sb-burgers-cover.jpg'),
          path: '/sb-burgers',
        },
        {
          name: 'Батискаф',
          cover: require('./../assets/img/projects/batiskaf/batiskaf-cover.png'),
          path: '/batiskaf',
        },
        {
          name: 'Dnevnik',
          cover: require('./../assets/img/projects/dnevnik/dnevnik-cover.jpg'),
          path: 'dnevnik',
        },
        {
          name: 'Тихий',
          cover: require('./../assets/img/projects/tihii/tihii-cover.jpg'),
          path: '/tihii',
        },
        {
          name: 'Bad Clown Burgers',
          cover: require('./../assets/img/projects/bad-clown-burgers/bad-clown-burgers-cover.png'),
          path: '/bad-clown-burgers',
        },
        {
          name: 'Go gyros',
          cover: require('./../assets/img/projects/go-gyros/go-gyros-cover.jpg'),
          path: '/go-gyros',
        },
        {
          name: 'SB&Burgers',
          cover: require('./../assets/img/projects/sb-burgers-2/sb-burgers-cover.jpg'),
          path: '/sb-burgers-2',
        },
        {
          name: 'Барионикс',
          cover: require('./../assets/img/projects/barioniks/barioniks-cover.jpg'),
          path: '/barioniks',
        }
      ]
    }
  }
}
</script>

<style>
@import "./../assets/css/main-page.css";
</style>