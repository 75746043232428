<template>
  <div>
    <div class="page-container">
      <div class="slider-container">
        <swiper
            :slides-per-view="1"
            navigation
        >
          <swiper-slide
              :key="slide.id"
              v-for="slide in slider"
          >
            <img :src="slide.src" :alt="slide.alt">
          </swiper-slide>
        </swiper>
      </div>
      <a
          href="https://www.elledecoration.ru/interior/hotels-restaurants/kafe-dnevnik-v-sankt-peterburge-proekt-daryany-mamotenko-i-tsanarchitects-id6767731/"
          target="_blank"
          rel="nofollow"
          >
        <div
            :key="image.id"
            v-for="image in linkImages"
            class="img-container"
        >
          <img :src="image.src" :alt="image.alt">
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation]);

export default {
  name: 'PublicationsPage',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      slider: [
        {
          id: 'publications-3',
          src: require('./../assets/img/publications/publications-3.jpg'),
          alt: ''
        },
        {
          id: 'publications-4',
          src: require('./../assets/img/publications/publications-4.jpg'),
          alt: ''
        },
        {
          id: 'publications-5',
          src: require('./../assets/img/publications/publications-5.jpg'),
          alt: ''
        },
        {
          id: 'publications-1',
          src: require('./../assets/img/publications/publications-1.jpg'),
          alt: ''
        },
        {
          id: 'publications-2',
          src: require('./../assets/img/publications/publications-2.jpg'),
          alt: ''
        },
      ],
      linkImages: [
        {
          id: 'publications-img-1',
          src: require('./../assets/img/publications/publications-img-1.jpg'),
          alt: ''
        },
        {
          id: 'publications-img-2',
          src: require('./../assets/img/publications/publications-img-2.jpg'),
          alt: ''
        },
        {
          id: 'publications-img-3',
          src: require('./../assets/img/publications/publications-img-3.jpg'),
          alt: ''
        }
      ]
    }
  }
}
</script>

<style scoped>
.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-container img {
  max-width: 100%;
}
::v-deep(.swiper-button-prev) {
  color: #333333;
}
::v-deep(.swiper-button-next) {
  color: #333333;
}
</style>