<template>
  <div class="contacts-container">
    <div class="img-1">
      <img :src="img1" alt="">
    </div>
    <div class="img-2">
      <a href="mailto:ts@tsanarch.com">
        <img :src="img2" alt="">
      </a>
    </div>
    <a href="tel:79111747232" class="contacts-phone">+7 911 174 72 32</a>
  </div>
</template>

<script>
export default {
  name: 'ContactsPage',
  data() {
    return {
      img1: require('./../assets/img/contacts-img-1.png'),
      img2: require('./../assets/img/contacts-img-2.png'),
    }
  }
}
</script>

<style scoped>
.contacts-container {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
  align-items: center;
  justify-content: center;
}
.contacts-phone {
  color: #525252;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  margin-top: 30px;
}
</style>