<template>
  <div>
    <div class="project-description-container">
      <h1 class="project-detailed-title">{{ projectTitle }}</h1>
      <p
          v-for="text in projectDescription"
          :key="text.id"
          class="project-detailed-description">{{ text.text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectDescriptionContainer',
  props: ['projectTitle', 'projectDescription'],
}
</script>

<style scoped>
@import "./../assets/css/project-description-container.css";
</style>