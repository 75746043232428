<template>
  <div>
    <div class="project-detailed-photos">
      <div
          v-for="column in getPhotosList()"
          :key="column.id"
          class="project-detailed-photos-column">
        <div
            v-for="item in column.list"
            :key="item.id"
            @click="photoClicked(item.index)"
            class="project-detailed-photo"
        >
          <img :src="item.src" :alt="item.alt">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectDetailedPhotos',
  props: ['projectPhotos'],
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    getPhotosList() {
      if( this.windowWidth > 992 ){
        return this.projectPhotos;
      }

      let newList = [];
      for(let column of this.projectPhotos) {
        newList = newList.concat(column.list);
      }

      return [{
        id: 'id-column',
        list: newList.sort((a,b) => {
          return parseInt(a.index) < parseInt(b.index) ? -1 : 1;
        }),
      }];
    },
    photoClicked(index) {
      this.$emit('photo-clicked', index)
    },
    onResize() {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style scoped>
@import "./../assets/css/project-detailed-photos.css";
</style>