import { createWebHistory, createRouter } from "vue-router";
import MainPage from "@/pages/MainPage";
import ArchitecturePage from "@/pages/ArchitecturePage";
import PublicationsPage from "@/pages/PublicationsPage";
import ContactsPage from "@/pages/ContactsPage";
//import NotFound from "@/pages/NotFound";
//
// projects
//
import Hitch from "@/pages/projects/Hitch";
import CentralPark from '@/pages/projects/CentralPark';
import SBBurgers from "@/pages/projects/SBBurgers";
import Batiskaf from "@/pages/projects/Batiskaf";
import Dnevnik from "@/pages/projects/Dnevnik";
import Tihii from "@/pages/projects/Tihii";
import BadClownBurgers from "@/pages/projects/BadClownBurgers";
import GoGyros from "@/pages/projects/GoGyros";
import SBBurgers2 from "@/pages/projects/SBBurgers2";
import Barioniks from "@/pages/projects/Barioniks";
import JiloyDom from "@/pages/projects/JiloyDom";
import Dupleks from "@/pages/projects/Dupleks";
import NauchnyTsentr from "@/pages/projects/NauchnyTsentr";
import DomUOzera from "@/pages/projects/DomUOzera";
import EcoDom from "@/pages/projects/EcoDom";
import MicroDom from "@/pages/projects/MicroDom";
import Zenit from "@/pages/projects/Zenit";
import SeaCan from "@/pages/projects/SeaCan";
import Apartment from "@/pages/projects/Apartment";

const routes = [
	{
		path: '/',
		name: 'main',
		component: MainPage,
	},
	{
		path: '/publications',
		component: PublicationsPage,
	},
	{
		path: '/contacts',
		component: ContactsPage,
	},
	{
		path: '/hitch',
		component: Hitch
	},
	{
		path: '/central-park',
		component: CentralPark
	},
	{
		path: '/sb-burgers',
		component: SBBurgers
	},
	{
		path: '/batiskaf',
		component: Batiskaf
	},
	{
		path: '/dnevnik',
		component: Dnevnik
	},
	{
		path: '/tihii',
		component: Tihii
	},
	{
		path: '/bad-clown-burgers',
		component: BadClownBurgers
	},
	{
		path: '/go-gyros',
		component: GoGyros
	},
	{
		path: '/sb-burgers-2',
		component: SBBurgers2
	},
	{
		path: '/barioniks',
		component: Barioniks
	},
	{
		path: '/architecture',
		component: ArchitecturePage
	},
	{
		path: '/jiloy-dom',
		component: JiloyDom
	},
	{
		path: '/dupleks',
		component: Dupleks
	},
	{
		path: '/nauchny-tsentr',
		component: NauchnyTsentr
	},
	{
		path: '/dom-u-ozera',
		component: DomUOzera
	},
	{
		path: '/ecodom',
		component: EcoDom
	},
	{
		path: '/microdom',
		component: MicroDom
	},
	{
		path: '/apartment-74',
		component: Apartment
	},
	{
		path: '/zenit',
		component: Zenit
	},
	{
		path: '/sea-can',
		component: SeaCan
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior () {
		return { left: 0, top: 0 }
	}
});

export default router;