<template>
  <div class="page-container">
    <ProjectMainContainer :main-img="mainImg"/>

    <ProjectDescriptionContainer
        :project-title="projectTitle"
        :project-description="projectDescription"
    />

    <ProjectDetailedPhotos
        :project-photos="projectPhotos"
        @photo-clicked="photoClicked"
    />

    <ProjectPopupSlider
        :photos="projectPhotosPopup"
        :hidden="isPopupHidden"
        :initial-photo="initialPhoto"
        @close-popup="showPopup"
    />

    <ProjectsBottomMenu/>
  </div>
</template>

<script>
import ProjectMainContainer from './../../components/ProjectMainContainer';
import ProjectDescriptionContainer from './../../components/ProjectDescriptionContainer';
import ProjectDetailedPhotos from './../../components/ProjectDetailedPhotos';
import ProjectPopupSlider from './../../components/ProjectPopupSlider';
import ProjectsBottomMenu from './../../components/ProjectsBottomMenu';

export default {
  name: 'Apartment',
  components: {
    ProjectMainContainer,
    ProjectDescriptionContainer,
    ProjectDetailedPhotos,
    ProjectPopupSlider,
    ProjectsBottomMenu,
  },
  data() {
    return {
      mainImg: require('./../../assets/img/projects/apartment-74/apartment-74-main.jpg'),
      projectTitle: 'Apartment 74',
      projectDescription: [
        {
          id: 1,
          text: 'Команда: Максим Цыбин, Анисина Анастасия, при участии Екатерины Третьяковой',
        },
        {
          id: 2,
          text: 'Назначение: Квартира',
        },
        {
          id: 3,
          text: 'Площадь: 75 м²',
        },
        {
          id: 4,
          text: 'Адрес: г.Санкт-Петербург',
        },
        {
          id: 5,
          text: 'Фотограф: Александр Гутаковский'
        },
        {
          id: 5,
          text: 'Год: 2018'
        }
      ],
      projectPhotos: [
        {
          id: 'photos-column-1',
          list: [
            {
              id: 'column-1-photo-1',
              index: 1,
              src: require('./../../assets/img/projects/apartment-74/apartment-74-1.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-2',
              index: 4,
              src: require('./../../assets/img/projects/apartment-74/apartment-74-4.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-3',
              index: 7,
              src: require('./../../assets/img/projects/apartment-74/apartment-74-7.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-4',
              index: 10,
              src: require('./../../assets/img/projects/apartment-74/apartment-74-10.jpg'),
              alt: ''
            },
          ]
        },
        {
          id: 'photos-column-2',
          list: [
            {
              id: 'column-2-photo-1',
              index: 2,
              src: require('./../../assets/img/projects/apartment-74/apartment-74-2.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-2',
              index: 5,
              src: require('./../../assets/img/projects/apartment-74/apartment-74-5.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-3',
              index: 8,
              src: require('./../../assets/img/projects/apartment-74/apartment-74-8.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-4',
              index: 11,
              src: require('./../../assets/img/projects/apartment-74/apartment-74-11.jpg'),
              alt: ''
            },
          ]
        },
        {
          id: 'photos-column-3',
          list: [
            {
              id: 'column-3-photo-1',
              index: 3,
              src: require('./../../assets/img/projects/apartment-74/apartment-74-3.jpg'),
              alt: ''
            },
            {
              id: 'column-3-photo-2',
              index: 6,
              src: require('./../../assets/img/projects/apartment-74/apartment-74-6.jpg'),
              alt: ''
            },
            {
              id: 'column-3-photo-3',
              index: 9,
              src: require('./../../assets/img/projects/apartment-74/apartment-74-9.jpg'),
              alt: ''
            },
            {
              id: 'column-3-photo-4',
              index: 12,
              src: require('./../../assets/img/projects/apartment-74/apartment-74-12.jpg'),
              alt: ''
            },
            {
              id: 'column-3-photo-5',
              index: 13,
              src: require('./../../assets/img/projects/apartment-74/apartment-74-13.jpg'),
              alt: ''
            },
          ]
        }
      ],
      projectPhotosPopup: [
        {
          id: 'popup-photo-1',
          src: require('./../../assets/img/projects/apartment-74/apartment-74-1-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-2',
          src: require('./../../assets/img/projects/apartment-74/apartment-74-2-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-3',
          src: require('./../../assets/img/projects/apartment-74/apartment-74-3-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-4',
          src: require('./../../assets/img/projects/apartment-74/apartment-74-4-big.jpg'),
          alt: ''
        },{
          id: 'popup-photo-5',
          src: require('./../../assets/img/projects/apartment-74/apartment-74-5-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-6',
          src: require('./../../assets/img/projects/apartment-74/apartment-74-6-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-7',
          src: require('./../../assets/img/projects/apartment-74/apartment-74-7-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-8',
          src: require('./../../assets/img/projects/apartment-74/apartment-74-8-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-9',
          src: require('./../../assets/img/projects/apartment-74/apartment-74-9-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-10',
          src: require('./../../assets/img/projects/apartment-74/apartment-74-10-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-11',
          src: require('./../../assets/img/projects/apartment-74/apartment-74-11-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-12',
          src: require('./../../assets/img/projects/apartment-74/apartment-74-12-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-13',
          src: require('./../../assets/img/projects/apartment-74/apartment-74-13-big.jpg'),
          alt: ''
        },
      ],
      isPopupHidden: true,
      initialPhoto: 1,
    }
  },
  methods: {
    photoClicked(index) {
      this.initialPhoto = index;
      this.isPopupHidden = false;
    },
    showPopup(flag) {
      this.isPopupHidden = flag;
    }
  }
}
</script>