<template>
  <div class="page-container">
    <ProjectMainContainer :main-img="mainImg"/>

    <ProjectDescriptionContainer
        :project-title="projectTitle"
        :project-description="projectDescription"
    />

    <div class="about-container">
      <div class="about-text-container">
        <p class="about-text">
          «Батискаф»- аквацентр раннего развития для для новорождённых и детей до 9 лет расположен в г. Санкт-Петербурге, в Приморском районе, одном из крупнейших районов города, лидирующий по количеству рождаемости. Детский акваклуб стал новым центром притяжения семей из Приморского и близлежащих районов. В интерьере «Батискафа» сглаженные углы и обтекаемые декоративные элементы делают пространство дружелюбным и безопасным для детей, создают ощущение «перетекания» из одной зону в другую. Основной цвет – белый служит фоном для ярких акцентов мебели и цветового зонирования помещений.
        </p>
        <p class="about-text">
          При входе в аквацентр посетители попадают в светлое пространство с административной зоной, зоной парковки колясок, с просторной зоной для переодевания, где можно оставить верхнюю одежду в индивидуальных встроенных шкафчиках, медицинским кабинетом, который имеет сквозной выход в бассейн и с зоной ожидания родителей, откуда через стеклянную перегородку открывается вид на бассейны, где проходят занятия. В зоне ожидания родителей отдельным объемом расположен ресепшн.
        </p>
        <p class="about-text">
          Входная группа и зона бассейнов соединяются сквозным холлом, из которого можно попасть в спортивный зал, соляную пещеру, санузлы, техническое помещение и раздевалки. Раздевалкам было уделено отдельное внимание, т.к. в акваклубе предусмотрено сочетание индивидуальных пространств для разных семейных групп. Таким образом, папа с дочкой, мама с сыном или папа с сыном и мама с дочкой имеют разные раздевалки, душевые и выходы к бассейнам, что дает возможность обоим родителям посещать акваклуб независимо от пола ребенка. Так же предусмотрены раздевалки, душевые и санузлы для детей с ограниченными возможностями.
        </p>
        <p class="about-text">
          Через душевую зону посетители попадают в зону бассейнов, где находятся большая площадка для разминки на суше перед входом в воду и отдельно стоящий белый объём сауны. Рассеянный демирующийся свет, пастельные тона цветовых акцентов, светлые стены, переходящие в пол и потолок, создают комфортное, современное пространство для восприятия и занятий маленьких посетителей аквацентра.
        </p>
      </div>
      <div class="about-img-container" @click="hideLightbox(false)">
        <img class="about-img" :src="aboutImg" alt="">
      </div>
    </div>

    <ProjectDetailedPhotos
        :project-photos="projectPhotos"
        @photo-clicked="photoClicked"
    />

    <ProjectPopupSlider
        :photos="projectPhotosPopup"
        :hidden="isPopupHidden"
        :initial-photo="initialPhoto"
        @close-popup="hidePopup"
    />

    <ProjectsBottomMenu/>

    <div v-if="!isLightboxHidden" class="popup-lightbox-wrapper">
      <div class="close-btn-container">
        <span @click="hideLightbox(true)" class="close-btn"></span>
      </div>
      <div class="popup-lightbox-container">
        <img :src="aboutImgBig" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import ProjectMainContainer from './../../components/ProjectMainContainer';
import ProjectDescriptionContainer from './../../components/ProjectDescriptionContainer';
import ProjectDetailedPhotos from './../../components/ProjectDetailedPhotos';
import ProjectPopupSlider from './../../components/ProjectPopupSlider';
import ProjectsBottomMenu from './../../components/ProjectsBottomMenu';

export default {
  name: 'Batiskaf',
  components: {
    ProjectMainContainer,
    ProjectDescriptionContainer,
    ProjectDetailedPhotos,
    ProjectPopupSlider,
    ProjectsBottomMenu,
  },
  data() {
    return {
      mainImg: require('./../../assets/img/projects/batiskaf/batiskaf-main.png'),
      projectTitle: 'Аквацентр раннего развития "БАТИСКАФ"',
      projectDescription: [
        {
          id: 1,
          text: 'Команда: Максим Цыбин, Анисина Анастасия, при участии Третьяковой Екатерины',
        },
        {
          id: 2,
          text: 'Назначение: Детский акваклуб',
        },
        {
          id: 3,
          text: 'Площадь: 540 м²',
        },
        {
          id: 4,
          text: 'Адрес: г. Санкт-Петербург, Стародеревенская ул., 11 к.2',
        },
        {
          id: 5,
          text: 'Фотограф: Дмитрий Цыренщиков',
        },
        {
          id: 6,
          text: 'Год: 2018'
        }
      ],
      projectPhotos: [
        {
          id: 'photos-column-1',
          list: [
            {
              id: 'column-1-photo-1',
              index: 1,
              src: require('./../../assets/img/projects/batiskaf/batiskaf-1.png'),
              alt: ''
            },
            {
              id: 'column-1-photo-2',
              index: 4,
              src: require('./../../assets/img/projects/batiskaf/batiskaf-4.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-3',
              index: 6,
              src: require('./../../assets/img/projects/batiskaf/batiskaf-6.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-4',
              index: 9,
              src: require('./../../assets/img/projects/batiskaf/batiskaf-9.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-5',
              index: 11,
              src: require('./../../assets/img/projects/batiskaf/batiskaf-11.jpg'),
              alt: ''
            },
          ]
        },
        {
          id: 'photos-column-2',
          list: [
            {
              id: 'column-2-photo-1',
              index: 2,
              src: require('./../../assets/img/projects/batiskaf/batiskaf-2.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-2',
              index: 7,
              src: require('./../../assets/img/projects/batiskaf/batiskaf-7.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-3',
              index: 10,
              src: require('./../../assets/img/projects/batiskaf/batiskaf-10.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-4',
              index: 12,
              src: require('./../../assets/img/projects/batiskaf/batiskaf-12.jpg'),
              alt: ''
            },
          ]
        },
        {
          id: 'photos-column-3',
          list: [
            {
              id: 'column-3-photo-1',
              index: 3,
              src: require('./../../assets/img/projects/batiskaf/batiskaf-3.png'),
              alt: ''
            },
            {
              id: 'column-3-photo-2',
              index: 5,
              src: require('./../../assets/img/projects/batiskaf/batiskaf-5.png'),
              alt: ''
            },
            {
              id: 'column-3-photo-3',
              index: 8,
              src: require('./../../assets/img/projects/batiskaf/batiskaf-8.jpg'),
              alt: ''
            },
            {
              id: 'column-3-photo-3',
              index: 13,
              src: require('./../../assets/img/projects/batiskaf/batiskaf-13.jpg'),
              alt: ''
            },
          ]
        }
      ],
      projectPhotosPopup: [
        {
          id: 'popup-photo-1',
          src: require('./../../assets/img/projects/batiskaf/batiskaf-1-big.png'),
          alt: ''
        },
        {
          id: 'popup-photo-2',
          src: require('./../../assets/img/projects/batiskaf/batiskaf-2-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-3',
          src: require('./../../assets/img/projects/batiskaf/batiskaf-3-big.png'),
          alt: ''
        },
        {
          id: 'popup-photo-4',
          src: require('./../../assets/img/projects/batiskaf/batiskaf-4-big.jpg'),
          alt: ''
        },{
          id: 'popup-photo-5',
          src: require('./../../assets/img/projects/batiskaf/batiskaf-5-big.png'),
          alt: ''
        },
        {
          id: 'popup-photo-6',
          src: require('./../../assets/img/projects/batiskaf/batiskaf-6-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-7',
          src: require('./../../assets/img/projects/batiskaf/batiskaf-7-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-8',
          src: require('./../../assets/img/projects/batiskaf/batiskaf-8-big.png'),
          alt: ''
        },
        {
          id: 'popup-photo-9',
          src: require('./../../assets/img/projects/batiskaf/batiskaf-9-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-10',
          src: require('./../../assets/img/projects/batiskaf/batiskaf-10-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-11',
          src: require('./../../assets/img/projects/batiskaf/batiskaf-11-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-12',
          src: require('./../../assets/img/projects/batiskaf/batiskaf-12-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-13',
          src: require('./../../assets/img/projects/batiskaf/batiskaf-13-big.jpg'),
          alt: ''
        },
      ],
      isPopupHidden: true,
      isLightboxHidden: true,
      initialPhoto: 1,
      aboutImg: require('./../../assets/img/projects/batiskaf/batiskaf-plan.jpg'),
      aboutImgBig: require('./../../assets/img/projects/batiskaf/batiskaf-plan-big.jpg'),
    }
  },
  methods: {
    photoClicked(index) {
      this.initialPhoto = index;
      this.isPopupHidden = false;
    },
    hidePopup(flag) {
      this.isPopupHidden = flag;
    },
    hideLightbox(flag) {
      this.isLightboxHidden = flag;
    }
  }
}
</script>

<style scoped>
@import "./../../assets/css/project-page.css";
</style>