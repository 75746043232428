<template>
  <div class="page-container">
    <ProjectMainContainer :main-img="mainImg"/>

    <ProjectDescriptionContainer
        :project-title="projectTitle"
        :project-description="projectDescription"
    />

    <div class="about-container">
      <div class="about-text-container">
        <p class="about-text">
          Проанализировав данный участок, первостепенной  задачей стало комплексное освоение территории, путем разделения пространства на функциональные зоны  и  сохранение имеющихся зеленых насаждений. Существующий участок формирует будущее размещение объемов,  а здания  не нарушают сложившиеся зеленые зоны. Таким образом , парк и общественные пространства  на данном участке станут  рекреационой зоной для всех горожан, а расположенный там дворец науки и техники привлечет  будующих  учеников.
        </p>
        <p class="about-text">
          На данном участке 6 основных объектов:
          <br>- музей науки и техники «эврика» с абсерваторией
          <br>- учебный блок с четырмя лабораториями (нанолаборатория, лаборатория по изучению энергосбережения, космическая лаборатория и лаборатория по изучению работотехнических устройств)
          <br>- спортивный блок
          <br>- многофункциональная крытая площадка с устройством авиакордодромом на кровле
          <br>- гостиница
          <br>- фондохранилище
        </p>
        <p class="about-text">
          Планировки всех зданий ,независимо от их этажности, позволяют посещать данный комплекс маломобильной группе населения, в каждом здании предусмотрены лифты и подьемники.
        </p>
        <p class="about-text">
          Объединяют здания спортивные и учебные открытые площадки для массовых мероприятий, аналогом для прорисоки которых ,как и всего комплекса, стала микросхема.
        </p>
      </div>
    </div>

    <ProjectDetailedPhotos
        :project-photos="projectPhotos"
        @photo-clicked="photoClicked"
    />

    <ProjectPopupSlider
        :photos="projectPhotosPopup"
        :hidden="isPopupHidden"
        :initial-photo="initialPhoto"
        @close-popup="showPopup"
    />

    <ProjectsBottomMenu
        :objects-type="'architecture'"
    />
  </div>
</template>

<script>
import ProjectMainContainer from './../../components/ProjectMainContainer';
import ProjectDescriptionContainer from './../../components/ProjectDescriptionContainer';
import ProjectDetailedPhotos from './../../components/ProjectDetailedPhotos';
import ProjectPopupSlider from './../../components/ProjectPopupSlider';
import ProjectsBottomMenu from './../../components/ProjectsBottomMenu';

export default {
  name: 'NauchnyTsentr',
  components: {
    ProjectMainContainer,
    ProjectDescriptionContainer,
    ProjectDetailedPhotos,
    ProjectPopupSlider,
    ProjectsBottomMenu,
  },
  data() {
    return {
      mainImg: require('./../../assets/img/projects/nauchny-tsentr/nauchny-tsentr-main.jpg'),
      projectTitle: 'Научный центр',
      projectDescription: [
        {
          id: 1,
          text: 'Команда: Максим Цыбин, Анисина Анастасия',
        },
        {
          id: 2,
          text: 'Назначение: Молодежный научный центр',
        },
        {
          id: 3,
          text: 'Площадь: 6,75 га',
        },
        {
          id: 4,
          text: 'Адрес: г.Санкт-Петербург',
        },
        {
          id: 5,
          text: 'Год: 2015'
        }
      ],
      projectPhotos: [
        {
          id: 'photos-column-1',
          list: [
            {
              id: 'column-1-photo-1',
              index: 1,
              src: require('./../../assets/img/projects/nauchny-tsentr/nauchny-tsentr-1.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-2',
              index: 4,
              src: require('./../../assets/img/projects/nauchny-tsentr/nauchny-tsentr-4.jpg'),
              alt: ''
            },
          ]
        },
        {
          id: 'photos-column-2',
          list: [
            {
              id: 'column-2-photo-1',
              index: 2,
              src: require('./../../assets/img/projects/nauchny-tsentr/nauchny-tsentr-2.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-2',
              index: 5,
              src: require('./../../assets/img/projects/nauchny-tsentr/nauchny-tsentr-5.jpg'),
              alt: ''
            },
          ]
        },
        {
          id: 'photos-column-3',
          list: [
            {
              id: 'column-3-photo-1',
              index: 3,
              src: require('./../../assets/img/projects/nauchny-tsentr/nauchny-tsentr-3.jpg'),
              alt: ''
            },
          ]
        }
      ],
      projectPhotosPopup: [
        {
          id: 'popup-photo-1',
          src: require('./../../assets/img/projects/nauchny-tsentr/nauchny-tsentr-1-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-2',
          src: require('./../../assets/img/projects/nauchny-tsentr/nauchny-tsentr-2-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-3',
          src: require('./../../assets/img/projects/nauchny-tsentr/nauchny-tsentr-3-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-4',
          src: require('./../../assets/img/projects/nauchny-tsentr/nauchny-tsentr-4-big.jpg'),
          alt: ''
        },{
          id: 'popup-photo-5',
          src: require('./../../assets/img/projects/nauchny-tsentr/nauchny-tsentr-5-big.jpg'),
          alt: ''
        },
      ],
      isPopupHidden: true,
      initialPhoto: 1,
    }
  },
  methods: {
    photoClicked(index) {
      this.initialPhoto = index;
      this.isPopupHidden = false;
    },
    showPopup(flag) {
      this.isPopupHidden = flag;
    }
  }
}
</script>

<style scoped>
@import "./../../assets/css/project-page.css";
</style>