<template>
  <div class="page-container">
    <ProjectMainContainer :main-img="mainImg"/>

    <ProjectDescriptionContainer
        :project-title="projectTitle"
        :project-description="projectDescription"
    />

    <div class="about-container">
      <div class="about-text-container">
        <p class="about-text">
          «Барионикс»- аквапарк в г. Казань, третьей столице России, находится в одном из крупнейших районов города, на берегу реки Волги.
        </p>
        <p class="about-text">
          Нашей задачей стояло сделать реновацию интерьера общественных зон аквапарка, планировка которого должна обеспечить правильное распределение потоков людей, а так же организовать просторную входную зону, предусмотреть дополнительные семейные раздевалки с отдельными кабинками для переодевания и обеспечить удобный доступ людям с ограниченными возможностями.
        </p>
        <p class="about-text">
          Раздевалкам было уделено отдельное внимание, т.к. в аквапарке предусмотрено сочетание индивидуальных пространств для разных семейных групп. Таким образом, папа с дочкой, мама с сыном или папа с сыном и мама с дочкой имеют разные зоны для переодевания, что дает возможность обоим родителям посещать аквапарк независимо от пола ребенка. Так же предусмотрены раздевалки, душевые и санузлы для людей с ограниченными возможностями.
        </p>
        <p class="about-text">
          ешив эти задачи, мы постарались сделать лаконичный интерьер с яркими акцентами отдельно выделенных зон, удобной и понятной инфографикой, а так же индивидуально разработанной мебелью и декоративными элементами.
        </p>
      </div>
    </div>

    <ProjectDetailedPhotos
        :project-photos="projectPhotos"
        @photo-clicked="photoClicked"
    />

    <ProjectPopupSlider
        :photos="projectPhotosPopup"
        :hidden="isPopupHidden"
        :initial-photo="initialPhoto"
        @close-popup="showPopup"
    />

    <ProjectsBottomMenu/>
  </div>
</template>

<script>
import ProjectMainContainer from './../../components/ProjectMainContainer';
import ProjectDescriptionContainer from './../../components/ProjectDescriptionContainer';
import ProjectDetailedPhotos from './../../components/ProjectDetailedPhotos';
import ProjectPopupSlider from './../../components/ProjectPopupSlider';
import ProjectsBottomMenu from './../../components/ProjectsBottomMenu';

export default {
  name: 'Barioniks',
  components: {
    ProjectMainContainer,
    ProjectDescriptionContainer,
    ProjectDetailedPhotos,
    ProjectPopupSlider,
    ProjectsBottomMenu,
  },
  data() {
    return {
      mainImg: require('./../../assets/img/projects/barioniks/barioniks-main.jpg'),
      projectTitle: 'Спа/Аквапарк “БАРИОНИКС”',
      projectDescription: [
        {
          id: 1,
          text: 'Команда: Максим Цыбин, Анисина Анастасия, при участии Артема Алексеева',
        },
        {
          id: 2,
          text: 'Назначение: Спа/Аквапарк',
        },
        {
          id: 3,
          text: 'Площадь: 1200 м²',
        },
        {
          id: 4,
          text: 'Адрес: г.Казань, ул. Мазита Гафури, 46',
        },
        {
          id: 5,
          text: 'Фотограф: Артем Нуртдинов',
        },
        {
          id: 6,
          text: 'Год: 2016'
        }
      ],
      projectPhotos: [
        {
          id: 'photos-column-1',
          list: [
            {
              id: 'column-1-photo-1',
              index: 1,
              src: require('./../../assets/img/projects/barioniks/barioniks-1.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-2',
              index: 4,
              src: require('./../../assets/img/projects/barioniks/barioniks-4.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-3',
              index: 7,
              src: require('./../../assets/img/projects/barioniks/barioniks-7.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-4',
              index: 10,
              src: require('./../../assets/img/projects/barioniks/barioniks-10.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-5',
              index: 13,
              src: require('./../../assets/img/projects/barioniks/barioniks-13.jpg'),
              alt: ''
            },
            {
              id: 'column-1-photo-6',
              index: 16,
              src: require('./../../assets/img/projects/barioniks/barioniks-16.jpg'),
              alt: ''
            },
          ]
        },
        {
          id: 'photos-column-2',
          list: [
            {
              id: 'column-2-photo-1',
              index: 2,
              src: require('./../../assets/img/projects/barioniks/barioniks-2.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-2',
              index: 5,
              src: require('./../../assets/img/projects/barioniks/barioniks-5.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-3',
              index: 8,
              src: require('./../../assets/img/projects/barioniks/barioniks-8.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-4',
              index: 11,
              src: require('./../../assets/img/projects/barioniks/barioniks-11.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-5',
              index: 14,
              src: require('./../../assets/img/projects/barioniks/barioniks-14.jpg'),
              alt: ''
            },
            {
              id: 'column-2-photo-6',
              index: 17,
              src: require('./../../assets/img/projects/barioniks/barioniks-17.jpg'),
              alt: ''
            },
          ]
        },
        {
          id: 'photos-column-3',
          list: [
            {
              id: 'column-3-photo-1',
              index: 3,
              src: require('./../../assets/img/projects/barioniks/barioniks-3.jpg'),
              alt: ''
            },
            {
              id: 'column-3-photo-2',
              index: 6,
              src: require('./../../assets/img/projects/barioniks/barioniks-6.jpg'),
              alt: ''
            },
            {
              id: 'column-3-photo-3',
              index: 9,
              src: require('./../../assets/img/projects/barioniks/barioniks-9.jpg'),
              alt: ''
            },
            {
              id: 'column-3-photo-4',
              index: 12,
              src: require('./../../assets/img/projects/barioniks/barioniks-12.jpg'),
              alt: ''
            },
            {
              id: 'column-3-photo-5',
              index: 15,
              src: require('./../../assets/img/projects/barioniks/barioniks-15.jpg'),
              alt: ''
            },
            {
              id: 'column-3-photo-6',
              index: 18,
              src: require('./../../assets/img/projects/barioniks/barioniks-18.jpg'),
              alt: ''
            },
          ]
        }
      ],
      projectPhotosPopup: [
        {
          id: 'popup-photo-1',
          src: require('./../../assets/img/projects/barioniks/barioniks-1-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-2',
          src: require('./../../assets/img/projects/barioniks/barioniks-2-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-3',
          src: require('./../../assets/img/projects/barioniks/barioniks-3-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-4',
          src: require('./../../assets/img/projects/barioniks/barioniks-4-big.jpg'),
          alt: ''
        },{
          id: 'popup-photo-5',
          src: require('./../../assets/img/projects/barioniks/barioniks-5-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-6',
          src: require('./../../assets/img/projects/barioniks/barioniks-6-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-7',
          src: require('./../../assets/img/projects/barioniks/barioniks-7-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-8',
          src: require('./../../assets/img/projects/barioniks/barioniks-8-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-9',
          src: require('./../../assets/img/projects/barioniks/barioniks-9-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-10',
          src: require('./../../assets/img/projects/barioniks/barioniks-10-big.jpg'),
          alt: ''
        },{
          id: 'popup-photo-11',
          src: require('./../../assets/img/projects/barioniks/barioniks-11-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-12',
          src: require('./../../assets/img/projects/barioniks/barioniks-12-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-13',
          src: require('./../../assets/img/projects/barioniks/barioniks-13-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-14',
          src: require('./../../assets/img/projects/barioniks/barioniks-14-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-15',
          src: require('./../../assets/img/projects/barioniks/barioniks-15-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-16',
          src: require('./../../assets/img/projects/barioniks/barioniks-16-big.jpg'),
          alt: ''
        },{
          id: 'popup-photo-17',
          src: require('./../../assets/img/projects/barioniks/barioniks-17-big.jpg'),
          alt: ''
        },
        {
          id: 'popup-photo-18',
          src: require('./../../assets/img/projects/barioniks/barioniks-18-big.jpg'),
          alt: ''
        },
      ],
      isPopupHidden: true,
      initialPhoto: 1,
    }
  },
  methods: {
    photoClicked(index) {
      this.initialPhoto = index;
      this.isPopupHidden = false;
    },
    showPopup(flag) {
      this.isPopupHidden = flag;
    }
  }
}
</script>

<style scoped>
@import "./../../assets/css/project-page.css";
</style>