import { createApp } from 'vue'
import App from './App.vue'
import router from "@/routes";
import VueYandexMetrika from 'vue-v3-yandex-metrika'

const app = createApp(App)
app.use(router)
app.use(VueYandexMetrika, {
	id: 74641948,
	router: router,
	env: 'production',
})

app.mount( '#app')
